export const coursesColl = ref([
    {
        course_image: 'https://study-api.bibleawr.com/courses/60870e43af3c7b001c213458/447b55c2f5ee-lg.jpg',
        course_desc: '"Дивовижні факти" - міжнародний проект під управлінням "найбагатшої печерної людини" Даґа Бетчелора. Юнаком Даґ відкинув всі можливості, які подарувало йому народження в заможній американській родині, і пустився берега. Опинившись на самісінькому дні, ставши відлюдником у Каліфорнійській печері, Батчелор знаходить запилену Біблію, і його життя перевертається.',
        course_locale: 'uk',
        course_url: 'https://bibleawr.com/course/kpnd'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/60870ea7af3c7b001c2134d6/f505d4824456-lg.jpg',
        course_desc: '"Розкриваючи таємниці Біблійних пророцтв із Кемі Утман" став найпопулярнішим Біблійним відеокурсом 2020 року. На прохання глядачів Школа Біблії розробила інтерактивний формат улюблених уроків. 14 оригінальних тем, які сподобаються як новачкам, так і досвідченим дослідникам.',
        course_locale: 'uk',
        course_url: 'https://bibleawr.com/course/xrei'
    },

    {
        course_image: 'https://study-api.bibleawr.com/courses/62164428790d6b001cd43cd9/a13c6bd9735b-lg.jpg',
        course_desc: 'Ісус – головне вирішення людських потреб та проблем.  Якщо життєві питання та обставини заводять тебе в глухий кут і ти не знаєш де знайти рішення?  Ти загубився і не можеш знайти дорогу назад?  Пастор Алехандро Буйон допоможе тобі знайти вирішення всіх проблем у твоєму житті.  Ти знайдеш вихід із кожного глухого кута в який заводить тебе гріх і в результаті зрозумієш що Ісус - Єдина надія.',
        course_locale: 'uk',
        course_url: 'https://bibleawr.com/course/rkpq'
    },

    {
        course_image: 'https://study-api.bibleawr.com/courses/60870ed9af3c7b001c213507/2326da02427b-lg.jpg',
        course_desc: 'Курс «Вірую» розповість про те, як молитва захищає від зла? Яка користь від того, що я знаю Символи віри? Що буде після смерті? Хто такий антихрист? Як уникнути страшного суду? Розуміння цих тем розвіє найпоширеніші забобони та відкриє справжню віру, що здатна пересувати гори.Ісус сказав: «і пізнаєте правду, а правда вас вільними зробить!»',
        course_locale: 'uk',
        course_url: 'https://bibleawr.com/course/fsyp'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/61b51bd4fb83da001caf957c/gd47b4e0c2b4-lg.jpg',
        course_desc: 'Курс "10 запитань до Бога" - це чудова можливість для людей, які шукають глибоких відповідей на складні питання життя. У цьому курсі ви отримаєте можливість розглянути десять ключових аспектів життя через призму духовності та віри. Ви поглибите своє розуміння самого себе, свого місця у світі і свого відношення до Бога.',
        course_locale: 'uk',
        course_url: 'https://bibleawr.com/course/xfwl'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/64b14c114f5bef001be30c7d/7609132540af-lg.jpg',
        course_desc: '«Матриця знань» - Дитячий курс, спрямований на підлітків, який допоможе їм розуміти Бога на підлітковому рівні. Курс ставить за мету поглибити знання про Бога, Його любов до людини та основні духовні істини. Під час курсу підлітки будуть вивчати про три особи Божества, розкривати поняття душі, досліджувати Божий план спасіння та набувати більш глибокого розуміння Бога. Весь матеріал буде подано у формі діалогів, що допоможе залучити підлітків до активної участі в процесі навчання.',
        course_locale: 'uk',
        course_url: 'https://bibleawr.com/course/ywzz'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/63ced302b8901d001c4d2b4d/cover.jpg',
        course_desc: 'Біблія навчає дітей життєвим навичкам та цінностям через дитячий курс про життя сім\'ї олівців і різні події, з яких вони вчаться. Курс ґрунтується на історіях біблійних героїв, де діти засвоюють важливі уроки та цінності, які можна застосовувати у повсякденному житті. Вперед, друзі! Почнемо цю незабутню подорож разом!',
        course_locale: 'uk',
        course_url: 'https://bibleawr.com/course/jlut'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/644682c0196ba9001c34bd9a/f03b3c237beb-lg.jpg',
        course_desc: 'Жінки надії - Відкрийте силу Біблії в християнському житті, досліджуючи її значення та вплив. Ви розкриєте сутність християнського спасіння та його вплив на ваше життя, особливо як жінки. Приєднайтесь до нас, розширюйте знання, знаходьте внутрішню силу та з\'єднуйтесь з іншими жінками, що прагнуть надії. Реєструйтесь на курс "Жінки надії" зараз, і відкрийте неймовірні можливості для перетворення свого життя. ',
        course_locale: 'uk',
        course_url: 'https://bibleawr.com/course/lqds'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/649ab367eb0038001c34f720/7c5dad83a733-lg.jpg',
        course_desc: 'У сучасному світі, під тиском домашніх проблем, сім\'ї розпадаються. Кожні 7-8 новостворених шлюбів закінчуються розлученням. Хоча думка про те, що родина потребує допомоги, не викликає радості, радісно відчувати, що ця допомога близька! Творець шлюбу дав нам унікальний шлюбний посібник - святу Біблію. Її сторінки містять відповіді на всі сучасні сімейні проблеми. У цьому курсі ми будемо досліджувати глибокі принципи сімейного союзу та знаходити практичні відповіді на питання, які ставить перед нами життя. ',
        course_locale: 'uk',
        course_url: 'https://bibleawr.com/course/myzu'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/629f80dccde842001c14ad18/fe9f62dca24b-lg.jpg',
        course_desc: 'Ви задоволені своїм здоров\'ям? З відповіді на це питання починаються всі людські зміни на краще. Якщо нас щось не влаштовує у нашому здоров\'ї, тоді наш розум відкритий для змін. А з віком нас дедалі більше щось не влаштовує: знижується зір, починає «стрибати» тиск, болить спина, зменшується працездатність, часто болить голова, з\'являється задишка при звичайних фізичних навантаженнях, погіршується травлення… загалом, старіємо. І так хочеться почуватися як раніше, щоб нічого не боліло, і сил більше… Чи це можливо? Як це може статися? Саме про це цей курс.',
        course_locale: 'uk',
        course_url: 'https://bibleawr.com/course/kelu'
    },

    {
        course_image: 'https://study-api.bibleawr.com/courses/609bcb6510f157001c750232/cover.jpg',
        course_desc: '10 уроков, которые отвечают на вопросы, волнующие современную молодежь: Есть ли настоящая любовь? Будет ли конец злу, страданиям и смерти? Кто придумал секс? Как попасть на небо? Как Тебе позвонить? И другие…',
        course_locale: 'ru',
        course_url: 'https://bibleawr.com/course/kdfi'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/60870ec4af3c7b001c2134e5/cover.jpg',
        course_desc: '"Удивительные факты" - международный проект под управлением "самого богатого пещерного человека" Дага Бетчелора. Юношей Даг отверг все возможности, которые подарило ему рождение в богатой американской семье, и пустился по берегу. Оказавшись на самом дне, став отшельником в Калифорнийской пещере, Батчелор находит запыленную Библию, и его жизнь переворачивается. Бывший хиппи и наркозависимый получает свободу в Иисусе Христе и посвящает жизнь для проповеди о его чудесах.',
        course_locale: 'ru',
        course_url: 'https://bibleawr.com/course/onzt'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/6107bc641ac304001c75b56d/cover.jpg',
        course_desc: 'Курс «Дневник Реформации» наполнен идеями и мотивами, которые важны в жизни каждого человека. Исторический фон помогает увидеть, как обращение к Библии может менять судьбу не только отдельного человека, но и целых народов. Взгляд в прошлое Европы, когда она находилась в жесточайшем кризисе позволяет извлечь уроки веры, надежды и мотивирует изменить на лучшее свою жизнь сегодня.',
        course_locale: 'ru',
        course_url: 'https://bibleawr.com/course/dgqo'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/60870e6baf3c7b001c21347d/cover.jpg',
        course_desc: 'Bible prophecies have always raised many questions, especially from people who do not believe in them. The course "Prophecies of Hope" aims to make you say goodbye to all doubts about their authenticity and realize that the Bible is not only a historical achievement, but also a God-inspired book that has long served as a link between God and man.',
        course_locale: 'en',
        course_url: 'https://bibleawr.com/course/iwmn'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/652549dda16fbb001c5401ea/cover.jpg',
        course_desc: 'Revelation\'s most important prophecies explained in 10-15 minute episodes.',
        course_locale: 'en',
        course_url: 'https://bibleawr.com/course/paqn'
    },
    {
        course_image: 'https://study-api.bibleawr.com/courses/60dc9395bc2c8c001bbc571f/cover.jpg',
        course_desc: 'The prophecies of the Bible are vivid proof of the Bible\'s authenticity. For example, prediction the fall of world empires, the occurrence of epidemics, wars, and revolutions. This book speaks of the past, present, and near future. International speaker Cami Uthman has traveled the world researching the facts regarding these prophecies. She will tell you exactly how they are coming true today. You will see that they are being fulfilled today faster than ever before in the history of mankind.',
        course_locale: 'en',
        course_url: 'https://bibleawr.com/course/bcml'
    }
])