<script setup>
import { useApp } from "~/composables/useApp";
import { useCourses } from "~/composables/useCourses";

const props = defineProps(["modelValue", "planModel"]);
const emit = defineEmits(["update:modelValue"]);

const dialog = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val),
});

const { coursesColl } = useCourses();
const courses = computed(() =>
  coursesColl.value.filter((c) => c.course_locale == activeLocale.value)
);

function handleOnReadDone() {
  addPlansProgress({
    planSlug: props.planModel.plan_slug,
    planDay: selectedDay.value,
  });

  // close dialog after read
  dialog.value = false;
}
</script>
<template>
  <v-dialog
    v-model="dialog"
    class="devotion-dialog"
    :options="{
      height: 'var(--app-height)',
      width: '100vw',
      maxWidth: 'var(--screen-max-width)',
    }"
  >
    <v-navigation-header
      @goback="dialog = false"
      :label="transl('Courses')"
      class="px-3"
    ></v-navigation-header>

    <div class="dialogs-content" :ref="(el) => (dialogRef = el)">
      <div class="cards mt-4 mb-10">
        <div class="card mt-8" v-for="(course, index) in courses" :key="index">
          <div class="card-image">
            <img :src="course.course_image" alt="" />
          </div>
          <div class="card-body">
            <div class="description">
              {{ course.course_desc }}
            </div>

            <NuxtLink :to="course.course_url">
              <div class="button mt-4">{{ transl("More details") }}</div>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<style scoped>
.dialogs-content {
  overflow-y: auto;
  height: 100%;
  background-color: white;
  padding: 0 12px 0 20px;
  font-family: "Golos Text";
  height: calc(100vh - 50px);
}

.dialogs-content.settings-size-lg {
  font-size: 24px !important;
  line-height: 35px;
}

.dialogs-content.settings-size-md {
  font-size: 20px !important;
  line-height: 32px;
}

.dialogs-content.settings-size-sm {
  font-size: 16px !important;
  line-height: 28px;
}

.cards {
}
.card {
  display: grid;
  grid-template-columns: minmax(150px, 25%) auto;
  gap: 50px;
  padding: 0 30px 0 20px;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.card-image img {
  border-radius: 16px;
}
.description {
  font-size: 16px;
  line-height: 1.2;
}
.button {
  padding: 9px 20px;
  background-color: #00bfa4;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0 7px 12px 0 rgba(0, 191, 164, 0.25);
  box-shadow: 0 7px 12px #00bfa440;
}
@media (max-width: 700px) {
  .card {
    gap: 20px;
    padding: 0 25px 0 10px;
    grid-template-columns: minmax(100px, 25%) auto;
  }
  .dialogs-content {
    padding: 0 30px 0 30px;
  }
}
@media (max-width: 580px) {
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
}
@media (max-width: 350px) {
  .dialogs-content {
    padding: 0 15px 0 15px;
  }
}
</style>
